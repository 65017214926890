const Size = {
  large: "1100px",
  mid: "930px",
  small: "860px",
};

export const MediaQuery = {
  large: `@media screen and (max-width: ${Size.large})`,
  md: `@media screen and (max-width: ${Size.mid})`,
  sm: `@media screen and (max-width: ${Size.small})`,
};
