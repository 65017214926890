import WebHeader from "../../components/Web/WebHeader";
import WebFooter from "../../components/Web/WebFooter";
import StoreLinks from "../../components/common/StoreLink";
import { Fragment } from "react";

import review1 from "../../assets/images/WebReview_1.png";
import review2 from "../../assets/images/WebReview_2.png";
import review3 from "../../assets/images/WebReview_3.png";
import review4 from "../../assets/images/WebReview_4.png";
import Webfeature1 from "../../assets/images/WebFeature_1.png";
import Webfeature2 from "../../assets/images/WebFeature_2.png";
import Webfeature3 from "../../assets/images/WebFeature_3.png";

import * as S from "./WebPage.styled";

type Review = {
  id: number;
  src: string;
};

const WebMainPage = () => {
  const reviews: Review[] = [
    { id: 1, src: review1 },
    { id: 2, src: review2 },
    { id: 3, src: review3 },
    { id: 4, src: review4 },
  ];
  return (
    <Fragment>
      <WebHeader />
      <S.WebMainPage>
        <S.IntroSection>
          <h1>
            To remember your life <br /> and its <span>zourney.</span>
          </h1>
          <p>조르니, 삶이 추억이 되는 공간</p>
          <S.StoreLinkBlock>
            <StoreLinks />
          </S.StoreLinkBlock>
        </S.IntroSection>
        <S.FeatureSection>
          <S.FeatureBox>
            <img src={Webfeature1} alt="feature1" />
            <div>
              <S.FeatureTitle>
                방문한 장소에 <br />
                <span>추억</span>을 남겨보세요.
              </S.FeatureTitle>
              <S.FeatureDescription>
                <span>작년 생일, 지난 크리스마스, 다가올 배낭여행..</span>
                <br /> 잊고 싶지 않은 장소와 추억이 있나요? <br /> 장소를 지도에
                저장하고 글, 사진, 동영상으로 <br /> 찬란한 순간을 기록해
                보세요.
              </S.FeatureDescription>
            </div>
          </S.FeatureBox>
          <S.FeatureBox>
            <img src={Webfeature2} alt="feature2" />
            <div>
              <S.FeatureTitle>
                <span>우리들</span>만의 추억 <br />
                앨범을 만들어보세요.
              </S.FeatureTitle>
              <S.FeatureDescription>
                조르니는 멤버를 초대해 앨범을 만들고 <br />
                <span>함께 기록할 수 있어요.</span> <br /> 소중한 사람들과
                특별한 추억을 공유해 보세요.
              </S.FeatureDescription>
            </div>
          </S.FeatureBox>
          <S.FeatureBox>
            <img src={Webfeature3} alt="feature3" />
            <div>
              <S.FeatureTitle>
                지도를 <span>마음껏</span> <br /> 꾸며보세요.
              </S.FeatureTitle>
              <S.FeatureDescription>
                다양한 색생과 이모지를 조합해 지도에 <br />
                귀여운 마커들을 추가할 수 있어요. <br />
                <span>나만의 지도를 완성해 보세요.</span>
              </S.FeatureDescription>
            </div>
          </S.FeatureBox>
        </S.FeatureSection>
        <S.ReviewBlock>
          {reviews.map((item) => (
            <img src={item.src} alt="review" key={item.id}></img>
          ))}
        </S.ReviewBlock>
      </S.WebMainPage>
      <WebFooter />
    </Fragment>
  );
};

export default WebMainPage;
