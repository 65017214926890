import React from "react";
import MenuLinks from "../../components/common/MenuLink";

import styled from "styled-components";
import { color } from "../../constants/color";

interface Props {
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileMenu = ({ setMenuOpen }: Props) => {
  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <>
      <NavBlock>
        <MenuLinks />
      </NavBlock>
      <Layer onClick={closeMenu}></Layer>
    </>
  );
};

export default MobileMenu;

const NavBlock = styled.nav`
  position: sticky;
  background-color: #fff;
  padding: 10px 10px;
  z-index: 100;
  font-family: "AppleSDGothicNeoR";

  div {
    margin: 15px 10px;
    font-size: 1.1rem;
    cursor: pointer;
    color: ${color.base};
  }

  a {
    display: block;
    margin: 15px 10px;
    font-size: 1.1rem;
    color: ${color.base};
  }

  div:hover,
  a:hover {
    color: ${color.signature};
  }
`;

const Layer = styled.div`
  content: "";
  display: block;
  position: relative;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  height: 100vh;
`;
