import styled from "styled-components";
import { color } from "../../constants/color";

export const MobileMainPage = styled.main`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  margin: auto;
  padding-top: 55px;
`;

// Intro section

export const IntroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 30px auto;
`;

export const IntroTextArea = styled.div`
  position: relative;
  font-family: "mont";

  h1 {
    font-size: 2.7rem;
    margin: 0;
    color: ${color.base};
  }

  p {
    margin-top: 5px;
    font-family: "AppleSDGothicNeoR";
    font-size: 1rem;
    color: ${color.base};
  }
  span {
    color: ${color.signature};
  }
`;

export const StoreLinkBlock = styled.div`
  margin-top: 15px;
  margin-bottom: 25px;

  display: flex;

  img {
    width: 108px;
    margin: 0 3px;
  }
`;

// Feature Section

export const FeatureSection = styled.section`
  border-top: 1px solid ${color.border_color};
`;

export const FeatureBox = styled.div`
  margin-top: 40px;
  text-align: center;
  color: ${color.base};
  padding: 20px 0;

  img {
    width: 240px;
  }
`;

export const FeatureTitle = styled.p`
  font-family: "AppleSDGothicNeoEB";
  font-size: 1.5rem;
  line-height: 120%;
  margin-top: 15px;
  margin-bottom: 0;
`;

export const FeatureDescription = styled.p`
    font-family: "AppleSDGothicNeoM";
    color: ${color.light};
    line-height: 120%;
    font-size: 0.9rem;
    span {
      font-family: "AppleSDGothicNeoB";
    }
  }
`;
