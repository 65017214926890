import * as S from "./MobilePage.styled";
import { Fragment } from "react";

import StoreLinks from "../../components/common/StoreLink";
import MobileFooter from "../../components/Mobile/MobileFooter";
import MobileHeader from "../../components/Mobile/MobileHeader";
import ReviewSlider from "../../components/Mobile/ReviewSlider";

import feature1 from "../../assets/images/feature_1.png";
import feature2 from "../../assets/images/feature_2.png";
import feature3 from "../../assets/images/feature_3.png";

const MainPage = () => {
  return (
    <Fragment>
      <MobileHeader />
      <S.MobileMainPage>
        <S.IntroSection>
          <S.IntroTextArea>
            <h1>
              To remember <br /> your life and <br />
              its <span>zourney.</span>
            </h1>
            <p>조르니, 삶이 추억이 되는 공간</p>
          </S.IntroTextArea>
          <S.StoreLinkBlock>
            <StoreLinks />
          </S.StoreLinkBlock>
        </S.IntroSection>
        <S.FeatureSection>
          <S.FeatureBox>
            <img src={feature1} alt="feature1" />
            <div className="feature_text_area">
              <S.FeatureTitle>
                방문한 장소에 <br /> 추억을 남겨보세요.
              </S.FeatureTitle>
              <S.FeatureDescription>
                <span>작년 생일, 지난 크리스마스, 다가올 배낭여행..</span>
                <br /> 잊고 싶지 않은 장소와 추억이 있나요? <br /> 장소를 지도에
                저장하고 글, 사진, 동영상으로 <br /> 찬란한 순간을 기록해
                보세요.
              </S.FeatureDescription>
            </div>
          </S.FeatureBox>
          <S.FeatureBox>
            <img src={feature2} alt="feature2" />
            <div className="feature_text_area">
              <S.FeatureTitle>
                우리들만의 추억 <br /> 앨범을 만들어 보세요.
              </S.FeatureTitle>
              <S.FeatureDescription>
                조르니는 멤버를 초대해 앨범을 만들고 함께 <br /> 기록할 수
                있어요. 소중한 사람들과 특별한 <br /> 추억을 공유해 보세요.
              </S.FeatureDescription>
            </div>
          </S.FeatureBox>
          <S.FeatureBox>
            <img src={feature3} alt="feature3" />
            <div className="feature_text_area">
              <S.FeatureTitle>
                지도를 마음껏 <br /> 꾸며보세요.
              </S.FeatureTitle>
              <S.FeatureDescription>
                다양한 색상과 이모지를 조합해 지도에 <br />
                귀여운 마커들을 추가할 수 있어요. <br />
                ​나만의 지도를 완성해 보세요.
              </S.FeatureDescription>
            </div>
          </S.FeatureBox>
        </S.FeatureSection>
        <ReviewSlider />
      </S.MobileMainPage>
      <MobileFooter />
    </Fragment>
  );
};

export default MainPage;
