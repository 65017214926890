import { Fragment } from "react";

const MenuLinks = () => {
  return (
    <Fragment>
      <a href="https://blog.naver.com/zourney1222" target="blank">
        블로그
      </a>
      <a href="https://forms.gle/KmgXkGyDgbXcjCiB7" target="blank">
        문의
      </a>
      <a
        href="https://whitesoil.notion.site/9ba1a27de91f416b8c15b612e9def813"
        target="blank"
      >
        고객센터
      </a>
    </Fragment>
  );
};

export default MenuLinks;
