import MenuLinks from "../../components/common/MenuLink";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { color } from "../../constants/color";
import logo from "../../assets/images/logo_bottom.png";

const WebHeader = () => {
  return (
    <Header>
      <HeaderBox>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
        <HeaderLink>
          <MenuLinks />
        </HeaderLink>
      </HeaderBox>
    </Header>
  );
};

export default WebHeader;

const Header = styled.header`
  max-width: 1200px;
  min-width: 820px;
  padding: 30px 0 25px;
  font-family: "AppleSDGothicNeoR";
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
`;

const HeaderBox = styled.div`
  padding: 0 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 100px;
    height: 20px;
  }
`;

const HeaderLink = styled.nav`
    width: 180px;
    display: flex;
    justify-content: space-between;
    color: ${color.base};
    a {
      font-size: 18px;
      color: ${color.base};
    }

    a:hover {
      cursor: pointer;
      color: ${color.signature};
    }
  }
`;
