import React from "react";
import MobileMainPage from "./pages/MobilePage/MobilePage";
import WebMainPage from "./pages/WebPage/WebPage";
import GlobalStyle from "./styles/global";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BrowserView, MobileOnlyView, TabletView } from "react-device-detect";

const App = () => {
  return (
    <div className="App">
      <GlobalStyle />
      <BrowserRouter>
        <BrowserView>
          <Routes>
            <Route path="/" element={<WebMainPage />} />
          </Routes>
        </BrowserView>
        <TabletView>
          <WebMainPage />
        </TabletView>
        <MobileOnlyView>
          <Routes>
            <Route path="/" element={<MobileMainPage />} />
          </Routes>
        </MobileOnlyView>
      </BrowserRouter>
    </div>
  );
};

export default App;
