import WebAppstore from "../../assets/images/WebAppstore.png";
import WebGooglePlay from "../../assets/images/WebGooglePlay.png";
import appstore from "../../assets/images/appstore.png";
import playstore from "../../assets/images/playstore.png";
import { BrowserView, MobileOnlyView, TabletView } from "react-device-detect";
import { Fragment } from "react";

const StoreLinks = () => {
  return (
    <Fragment>
      <a
        href="https://play.google.com/store/apps/details?id=io.zourney.app"
        target="blank"
      >
        <BrowserView>
          <img src={WebGooglePlay} alt="playstore" />
        </BrowserView>
        <TabletView>
          <img src={WebGooglePlay} alt="playstore" />
        </TabletView>
        <MobileOnlyView>
          <img src={playstore} alt="playstore" />
        </MobileOnlyView>
      </a>
      <a
        href="https://apps.apple.com/kr/app/%EC%A1%B0%EB%A5%B4%EB%8B%88-%EC%A7%80%EB%8F%84-%EC%9D%BC%EA%B8%B0/id1594139992"
        target="blank"
      >
        <BrowserView>
          <img src={WebAppstore} alt="appstore" />
        </BrowserView>
        <TabletView>
          <img src={WebAppstore} alt="appstore" />
        </TabletView>
        <MobileOnlyView>
          <img src={appstore} alt="appstore" />
        </MobileOnlyView>
      </a>
    </Fragment>
  );
};

export default StoreLinks;
