import { createGlobalStyle } from "styled-components";
import AppleSDGothicNeoB from "../assets/fonts/AppleSDGothicNeoB.ttf";
import AppleSDGothicNeoEB from "../assets/fonts/AppleSDGothicNeoEB.ttf";
import AppleSDGothicNeoL from "../assets/fonts/AppleSDGothicNeoL.ttf";
import AppleSDGothicNeoR from "../assets/fonts/AppleSDGothicNeoR.ttf";
import AppleSDGothicNeoM from "../assets/fonts/AppleSDGothicNeoM.ttf";
import LiberationSansBold from "../assets/fonts/LiberationSans-Bold.ttf";
import LiberationSansRegular from "../assets/fonts/LiberationSans-Regular.ttf";
import MontHeavyDEMO from "../assets/fonts/Mont-HeavyDEMO.otf";

export default createGlobalStyle`
 @font-face {
    font-family: 'AppleSDGothicNeoB';
    src: local('AppleSDGothicNeoB'), local('AppleSDGothicNeoB');
    font-style: normal;
    src: url(${AppleSDGothicNeoB}) format('truetype');
 }
 @font-face {
  font-family: 'AppleSDGothicNeoEB';
  src: local('AppleSDGothicNeoEB'), local('AppleSDGothicNeoEB');
  font-style: normal;
  src: url(${AppleSDGothicNeoEB}) format('truetype');
 }
 @font-face {
  font-family: 'AppleSDGothicNeoL';
  src: local('AppleSDGothicNeoL'), local('AppleSDGothicNeoL');
  font-style: normal;
  src: url(${AppleSDGothicNeoL}) format('truetype');
 }

 @font-face {
   font-family: 'AppleSDGothicNeoR';
   src: local('AppleSDGothicNeoR'), local('AppleSDGothicNeoR');
   font-style: normal;
   src: url(${AppleSDGothicNeoR}) format('truetype');
  }
 
 @font-face {
  font-family: 'AppleSDGothicNeoM';
  src: local('AppleSDGothicNeoM'), local('AppleSDGothicNeoM');
  font-style: normal;
  src: url(${AppleSDGothicNeoM}) format('truetype');
 }

 @font-face {
  font-family: 'LiberationSansBold';
  src: local('LiberationSansBold'), local('LiberationSansBold');
  font-style: normal;
  src: url(${LiberationSansBold}) format('truetype');
 }
 @font-face {
  font-family: 'LiberationSansRegular';
  src: local('LiberationSansRegular'), local('LiberationSansRegular');
  font-style: normal;
  src: url(${LiberationSansRegular}) format('truetype');
 }

 @font-face {
  font-family: 'Mont';
  src: local('MontHeavyDEMO'), local('MontHeavyDEMO');
  font-style: normal;
  src: url(${MontHeavyDEMO}) format('truetype');
 }

 body {
  margin: 0;
  padding: 0;
 }
 
 a {
  text-decoration : none;
  color : black;
  cursor: pointer;
 }

address {
   font-style : normal;
}

li {
   list-style: none;
}

ul {
   margin: 0;
   padding: 0;
}

h6 {
   margin:0;
   padding: 0;
   font-weight: normal;
}

`;
