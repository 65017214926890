import TermsLinks from "../../components/common/TermLink";
import { Link } from "react-router-dom";

import { info } from "../../constants/info";

import styled from "styled-components";
import { color } from "../../constants/color";
import { MediaQuery } from "../../constants/size";
import logoBottom from "../../assets/images/logo_bottom.png";
import blogIcon from "../../assets/images/Web_blog_icon.png";

const WebFooter = () => {
  return (
    <Footer>
      <LogoBlock>
        <Link to="/">
          <img src={logoBottom} alt="logoBottom" />
        </Link>
      </LogoBlock>
      <ContactBlock>
        <p>
          <span>E.MAIL</span> {info.email}
        </p>
        <p>
          <span>FOLLOW US</span>
          <a href="https://blog.naver.com/zourney1222" target="blank">
            <img src={blogIcon} alt="blog_icon" />
          </a>
        </p>
        <TermsLinks />
      </ContactBlock>
      <InfoBlock>
        <ul>
          <li>
            COMPANY <span>{info.company}</span>
          </li>
          <li>
            OWNER <span>{info.owner}</span>
          </li>
          <li>
            BUSINESS NO. <span>{info.businessNo}</span>
          </li>
          <li>
            ADDRESS
            <span>{info.address}</span>
          </li>
        </ul>
        <p>{info.copyright}</p>
      </InfoBlock>
    </Footer>
  );
};

export default WebFooter;

const Footer = styled.footer`
  max-width: 1200px;
  min-width: 820px;
  height: 134px;
  margin: auto;
  border-top: 1px solid ${color.border_color};
  padding: 50px 0 60px 0;
  display: flex;
  font-family: "LiberationSansRegular";
  font-size: 15px;

  ${MediaQuery.md} {
    font-size: 13px;
  }
`;

const LogoBlock = styled.div`
  padding-left: 10%;
  display: flex;
  align-items: center;
  img {
    width: 115px;
  }
  ${MediaQuery.md} {
    img {
      width: 105px;
    }
  }
`;

const ContactBlock = styled.div`
  color: ${color.base};
  display: flex;
  flex-direction: column;
  padding: 0 80px 0 100px;
  span {
    font-family: "LiberationSansBold";
  }
  p {
    margin: 0 0 15px 0;
  }
  img {
    position: relative;
    top: 3px;
    width: 16px;
    padding-left: 7px;
  }

  a {
    color: ${color.term_color};
    font-family: "AppleSDGothicNeoR";
    padding-top: 3px;
  }

  ${MediaQuery.large} {
    padding: 0 50px 0 80px;
  }
  ${MediaQuery.sm} {
    padding: 0 30px 0 50px;
  }
`;

const InfoBlock = styled.address`
  color: ${color.base};
  span {
    color: ${color.light};
    padding-left: 5px;
  }
  p {
    margin: 0 0 7px 0;
    color: ${color.term_color};
    padding-top: 18px;
    font-weight: 600;
    font-family: "LiberationSansBold";
  }
  ul > li {
    padding-bottom: 7px;
  }
`;
