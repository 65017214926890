import TermsLinks from "../../components/common/TermLink";
import { info } from "../../constants/info";

import styled from "styled-components";
import { color } from "../../constants/color";
import logoBottom from "../../assets/images/logo_bottom.png";
import blogIcon from "../../assets/images/blog_icon.png";
import { Link } from "react-router-dom";

// 상수 값들을 constatns 폴더로 빼서, 관리하면 mobile과 pc버전 정보가 변했을 때, 더 관리하기 쉬움

const MobileFooter = () => {
  return (
    <Footer>
      <Link to="/">
        <img src={logoBottom} alt="logo_bottom" width="110px" />
      </Link>
      <EmailBlock>
        <span>E.MAIL</span> {info.email}
      </EmailBlock>
      <BlogBlock>
        <span>FOLLOW US</span>
        <a href="https://blog.naver.com/zourney1222" target="blank">
          <img src={blogIcon} alt="blog_icon" />
        </a>
      </BlogBlock>
      <InfoBlock>
        <ul>
          <li>
            COMPANY <span>{info.company}</span>
          </li>
          <li>
            OWNER <span>{info.owner}</span>
          </li>
          <li>
            BUSINESS NO. <span> {info.businessNo} </span>
          </li>
          <li className="address">
            ADDRESS
            <span>{info.address}</span>
          </li>
        </ul>
      </InfoBlock>
      <Copyright>{info.copyright}</Copyright>
      <Terms>
        <TermsLinks />
      </Terms>
    </Footer>
  );
};

export default MobileFooter;

const Footer = styled.footer`
  border-top: 1px solid ${color.border_color};
  padding-top: 35px;
  padding-left: 25px;
  padding-bottom: 100px;
  font-family: "LiberationSansRegular";
  font-size: 15px;
  color: ${color.base};
`;

const EmailBlock = styled.div`
  margin-top: 18px;
  span {
    font-family: "LiberationSansBold";
  }
`;

const BlogBlock = styled.div`
  display: flex;
  margin: 28px 0;
  img {
    width: 18px;
    margin-left: 8px;
  }
  span {
    font-family: "LiberationSansBold";
  }
`;

const InfoBlock = styled.address`
  margin-bottom: 17px;
  ul > li {
    margin: 7px 0;
  }
  span {
    color: ${color.light};
    margin-left: 5px;
  }
  .address {
    span {
      display: inline-flex;
      line-height: 150%;
      width: 215px;
    }
  }
`;

const Copyright = styled.div`
  font-family: "AppleSDGothicNeoB";
  margin-bottom: 10px;
`;

const Terms = styled.nav`
  display: flex;
  flex-direction: column;
  a {
    font-family: "AppleSDGothicNeoR";
    font-size: 15px;
    color: ${color.term_color};
    margin-bottom: 5px;
  }
`;
