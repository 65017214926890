import { Fragment } from "react";

const TermsLinks = () => {
  return (
    <Fragment>
      <a
        href="https://whitesoil.notion.site/16e11ff157b3474da4dff051c83038e8"
        target="blank"
      >
        개인정보 처리방침
      </a>
      <a
        href="https://whitesoil.notion.site/a5df2dfd2629490d9e9a52e64f872c1e"
        target="blank"
      >
        위치정보 이용약관
      </a>
      <a
        href="https://whitesoil.notion.site/9c3fac051a41483883f3302597f6b4a1"
        target="blank"
      >
        서비스 이용약관
      </a>
    </Fragment>
  );
};

export default TermsLinks;
