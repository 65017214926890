import { useState } from "react";
import MenuNav from "./MobileMenu";

import styled from "styled-components";
import logo from "../../assets/images/logo_bottom.png";
import menubar from "../../assets/images/menubar.png";
import { Link } from "react-router-dom";

const MobileHeader = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const menuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Header>
      <HeaderBlock>
        <Link to="/">
          <HeaderImg src={logo} alt="logo" width={"85px"} />
        </Link>
        <HeaderImg
          src={menubar}
          alt="menubar"
          width={"25px"}
          onClick={menuToggle}
        />
      </HeaderBlock>
      {menuOpen === true ? <MenuNav setMenuOpen={setMenuOpen} /> : null}
    </Header>
  );
};

export default MobileHeader;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
  height: 55px;
  z-index: 10;
`;

const HeaderBlock = styled.div`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img + img {
    cursor: pointer;
  }
`;

const HeaderImg = styled.img<{ width: string }>`
  width: ${(props) => props.width};
  display: block;
`;
