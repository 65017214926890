import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import review1 from "../../assets/images/review_1.png";
import review2 from "../../assets/images/review_2.png";
import review3 from "../../assets/images/review_3.png";
import review4 from "../../assets/images/review_4.png";

type Review = {
  id: number;
  src: string;
};

const ReviewSlider = () => {
  const settings = {
    variableWidth: true,
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Next />,
    prevArrow: <Prev />,
  };

  const reviews: Review[] = [
    { id: 1, src: review1 },
    { id: 2, src: review2 },
    { id: 3, src: review3 },
    { id: 4, src: review4 },
  ];

  return (
    <ReviewBlock>
      <ReviewItem>
        <Slider {...settings}>
          {reviews.map((item) => (
            <img src={item.src} alt="review" key={item.id}></img>
          ))}
        </Slider>
      </ReviewItem>
    </ReviewBlock>
  );
};
export default ReviewSlider;

const ReviewBlock = styled.section`
  padding: 50px 0 60px 0;
  width: 320px;
  margin: auto;
`;

const ReviewItem = styled.div`
  width: 250px;
  margin: auto;

  .slick-slide {
    width: 250px;
    padding: 0 20px;
  }

  .slick-list {
    margin: 0 -20px;
  }

  .slick-next:before,
  .slick-prev:before {
    display: none;
  }

  img {
    width: 300px;
  }
`;

const Next = styled.button`
  position: absolute;
  display: block;
  right: 5px;
  width: 45px;
  height: 250px;
`;

const Prev = styled.button`
  position: absolute;
  display: block;
  left: 5px;
  width: 45px;
  height: 250px;
  z-index: 1;
`;
