import styled from "styled-components";
import bgImg from "../../assets/images/bgImg.png";
import { color } from "../../constants/color";
import { MediaQuery } from "../../constants/size";

export const WebMainPage = styled.main`
  max-width: 1200px;
  min-width: 820px;
  margin: auto;
  background-image: url("${bgImg}");
  background-size: 1200px;
`;

// Intro

export const IntroSection = styled.section`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 180px 0 80px 0;

  font-family: "mont";
  font-size: 2.3rem;

  h1 {
    color: ${color.base};
    margin: 0;
  }

  p {
    font-family: "AppleSDGothicNeoR";
    margin: 18px 0;
    font-size: 23px;
    color: ${color.base};
  }

  span {
    color: ${color.signature};
  }
`;

export const StoreLinkBlock = styled.div`
  margin-top: 15px;
  display: flex;

  img {
    width: 130px;
    margin: 0 3px;
  }
`;

// Review Section

export const ReviewBlock = styled.section`
  width: 1100px;
  padding-bottom: 70px;
  margin: auto;
  text-align: center;

  img {
    padding: 0 10px;
    width: 250px;
  }

  ${MediaQuery.large} {
    width: 930px;

    img {
      padding: 0 6px;
      width: 220px;
    }
  }

  ${MediaQuery.md} {
    width: 860px;

    img {
      padding: 0 6px;
      width: 190px;
    }
  }

  ${MediaQuery.sm} {
    width: 820px;

    img {
      padding: 0 6px;
      width: 180px;
    }
  }
`;

// Feature Section

export const FeatureSection = styled.section`
  border-top: 1px solid ${color.border_color};
  margin: auto;
  padding-top: 270px;
  display: flex;
  flex-direction: column;
`;

export const FeatureBox = styled.div`
  padding-bottom: 170px;
  display: flex;
  align-items: center;
  padding-left: 100px;

  img {
    width: 440px;
    margin: 0 80px;
  }

  &:nth-child(2) {
    text-align: right;
    flex-direction: row-reverse;
    padding-right: 70px;
    img {
      width: 410px;
    }
  }

  &:nth-child(3) {
    padding-left: 140px;

    div {
      position: relative;
      top: -20px;
    }
    img {
      width: 385px;
    }
  }

  ${MediaQuery.large} {
    img {
      width: 380px;
      margin: 0 30px;
    }
  }

  ${MediaQuery.md} {
    padding-left: 60px;

    &:nth-child(3) {
      padding-left: 80px;
    }

    &:nth-child(2) {
      img {
        margin: 0 10px;
      }
      text-align: right;
      flex-direction: row-reverse;
      padding-right: 30px;
    }
  }
`;

export const FeatureTitle = styled.h2`
  font-family: "AppleSDGothicNeoEB";
  font-size: 40px;
  color: ${color.dark};
  line-height: 120%;
  font-weight: inherit;

  span {
    color: ${color.signature};
  }
  ${MediaQuery.large} {
    font-size: 35px;
  }
`;

export const FeatureDescription = styled.p`
  font-family: "AppleSDGothicNeoM";
  color: ${color.light};
  line-height: 120%;
  font-size: 17px;
  margin-top: 0;

  span {
    font-family: "AppleSDGothicNeoB";
  }

  ${MediaQuery.large} {
    font-size: 15px;
  }
`;
